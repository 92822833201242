<template>
  <div class="roal-rumble-card">
    <div class="roal-rumble-card__body">
      <transition name="represent-image-transition" mode="out-in">
        <div
          key="before"
          v-if="state === 'before'"
          class="roal-rumble-card__number"
        >
          {{ row }}
        </div>

        <div
          key="available"
          v-else
          class="roal-rumble-card__image"
          :style="`background-image: url('${image}')`"
        >
          <v-icon class="roal-rumble-card__icon" v-if="icon">{{ icon }}</v-icon>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoyalRumbleImage",
  props: {
    state: String,
    url: String,
    icon: String,
    row: [String, Number]
  },
  computed: {
    image() {
      return decodeURI(this.url)
    }
  }
}
</script>

<style lang="scss">
.represent-image-transition {
  &-enter-active,
  &-leave-active {
    transition: transform 0.3s ease;
    transform: rotateY(0deg);
  }
  &-enter {
    transform: rotateY(-90deg);
  }
  &-leave-to {
    transform: rotateY(90deg);
  }
}

.roal-rumble-card {
  position: relative;

  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &__body {
    border: 1px solid #000;

    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__number {
    font-size: 50px;
    color: #fff;
    background-color: $secondary_accent_color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__icon {
    position: absolute;
    top: -4px;
    left: -4px;
    color: #000;
    background-color: #fff;
    padding: 6px 4px 2px 7px;
    font-size: 18px;
    border-radius: 4px;
  }
}
</style>
