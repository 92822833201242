<template>
  <v-flex d-flex class="represent-mission">
    <div class="represent-mission__scrollable">
      <div class="represent-mission__grid" ref="grid">
        <div
          class="represent-mission__column"
          v-for="(col, index) in imagesByCol"
          :key="index"
        >
          <RoyalRumbleImage
            v-for="(image, i) in col"
            :key="`image-${image.url}`"
            :state="image.state"
            :row="i + 1"
            class="represent-mission__sqaure"
            :class="{ 'click-on': isSelectOn(image.url, image.state, i) }"
            :style="{ width: cardWidth + `px` }"
            :url="image.url"
            :icon="image.icon"
            @click.native="onSelect(image.url, image.state, i)"
          />
        </div>
        <!-- </template> -->
      </div>
      <ResizeObserver @notify="handleResize" />
    </div>
  </v-flex>
</template>

<script>
import { GameMixin } from "@/mixins"
import { mapGetters, mapActions } from "vuex"
import { ResizeObserver } from "vue-resize"
import Mode from "@shared/enums/Mode"
import RoyalRumbleImage from "./RoyalRumbleImage"
import { chain } from "lodash"
import MissionCollection from "@shared/MissionCollection"

export default {
  name: "RoyalRumble",
  mixins: [GameMixin],
  props: {
    mode: String,
    mission: Object
  },
  components: {
    ResizeObserver,
    RoyalRumbleImage
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.grid.offsetHeight
      this.width = this.$refs.grid.offsetWidth
    })
  },
  data() {
    return { width: 0, height: 0 }
  },
  computed: {
    ...mapGetters({
      teams: "chats"
    }),
    ...mapGetters("auth", ["user", "isPlayer"]),
    ...mapGetters(["gameStatus", "teamID"]),
    ...mapGetters(["missionCompleted", "missionPlaysArray", "isScribe"]),
    ...mapGetters({ teams: "chats" }),
    missionID() {
      return this.mission?.id
    },
    isPlayOn() {
      return this.mode === Mode.Play
    },
    isSocialMode() {
      return this.mode === Mode.Social
    },
    twoSelected() {
      return this.selectedCards.length >= 2
    },
    firstSelect() {
      return this.selectedCards.length >= 1
    },
    selectedCards() {
      return this.missionPlaysArray.filter(
        play => play.teamID == this.teamID && play.missionID == this.missionID
      )
    },
    media() {
      return Array.isArray(this.gameStatus.media) ? this.gameStatus.media : []
    },
    playStep() {
      return MissionCollection.normalize(this.$store.getters.missions).find(
        mission =>
          mission.activityId === this.mission.activityId && mission.play
      )
    },
    imagesByTeam() {
      const imagesByTeam = chain(this.missionPlaysArray || [])
        .map(play => {
          return {
            url: play.answer[0].image,
            state: "after",
            icon: this.teams[play.teamID].icon,
            teamID: play.teamID
          }
        })
        .groupBy("teamID")
        .value()

      if (
        this.gameStatus.royalRumbleID &&
        this.gameStatus.royalRumbleID != 1000
      ) {
        return {
          [this.gameStatus.royalRumbleID]:
            imagesByTeam[this.gameStatus.royalRumbleID]
        }
      } else {
        return imagesByTeam
      }
    },
    representImages() {
      return this.mission.representImages || this.playStep.representImages
    },
    playImages() {
      if (!Array.isArray(this.representImages)) return []

      return chain(this.representImages)
        .map((url, index, arr) => {
          let state
          let icon = null

          const missionPlay = this.missionPlaysArray.find(
            play => play?.answer?.[0]?.image === url
          )

          if (missionPlay) {
            icon = this.teams[missionPlay.teamID].icon
            state = "after"
          } else {
            state = "before"
          }

          const column = index % (arr.length / 2)
          return { url, state, icon, column }
        })
        .groupBy("column")
        .value()
    },
    imagesByCol() {
      return this.isSocialMode ? this.imagesByTeam : this.playImages
    },
    cardWidth() {
      const { imagesByCol, width, height } = this
      const columnNumber = Object.keys(imagesByCol).length
      const expectedWidth = ~~(width / columnNumber) - 1

      if (expectedWidth * 2 < height) {
        return expectedWidth
      } else {
        return Math.floor(height / 2)
      }
    }
  },

  methods: {
    ...mapActions(["updateGameMedia"]),
    isSelectOn(url, state, i) {
      if (
        this.isPlayer &&
        this.isScribe &&
        !this.media.includes(url) &&
        this.isPlayOn &&
        state !== "after" &&
        !this.twoSelected
      ) {
        if (this.firstSelect) {
          // second card
          return i
        } else {
          // first card
          return !i
        }
      }
      return false
    },
    handleResize({ width, height }) {
      this.height = height
      this.width = width
    },
    async onSelect(url, state, i) {
      if (!this.isSelectOn(url, state, i)) {
        return
      }

      if (this.isHost) {
        this.updateGameMedia({ media: [...this.media, url] })
      } else {
        this.imageUrl = url
        this.hasImage = true
        this.missionStatus = "completed"
        await this.checkAnswer({
          sentMissionID: this.missionID
        })
      }
    }
  }
}
</script>

<style lang="scss">
.mission-score {
  display: none !important;
}
.represent-mission {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  // padding-top: 25px;
  // padding-bottom: 48px;

  &__scrollable {
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  &__grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    min-height: 100%;
    width: 100%;
  }

  &__sqaure {
    text-align: center;
    background-color: #fff;
    overflow: hidden;

    &.click-on {
      cursor: pointer;
    }
  }
}
</style>
