var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "represent-mission", attrs: { "d-flex": "" } },
    [
      _c(
        "div",
        { staticClass: "represent-mission__scrollable" },
        [
          _c(
            "div",
            { ref: "grid", staticClass: "represent-mission__grid" },
            _vm._l(_vm.imagesByCol, function (col, index) {
              return _c(
                "div",
                { key: index, staticClass: "represent-mission__column" },
                _vm._l(col, function (image, i) {
                  return _c("RoyalRumbleImage", {
                    key: "image-" + image.url,
                    staticClass: "represent-mission__sqaure",
                    class: {
                      "click-on": _vm.isSelectOn(image.url, image.state, i),
                    },
                    style: { width: _vm.cardWidth + "px" },
                    attrs: {
                      state: image.state,
                      row: i + 1,
                      url: image.url,
                      icon: image.icon,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onSelect(image.url, image.state, i)
                      },
                    },
                  })
                }),
                1
              )
            }),
            0
          ),
          _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }