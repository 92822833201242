var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "roal-rumble-card" }, [
    _c(
      "div",
      { staticClass: "roal-rumble-card__body" },
      [
        _c(
          "transition",
          { attrs: { name: "represent-image-transition", mode: "out-in" } },
          [
            _vm.state === "before"
              ? _c(
                  "div",
                  { key: "before", staticClass: "roal-rumble-card__number" },
                  [_vm._v(" " + _vm._s(_vm.row) + " ")]
                )
              : _c(
                  "div",
                  {
                    key: "available",
                    staticClass: "roal-rumble-card__image",
                    style: "background-image: url('" + _vm.image + "')",
                  },
                  [
                    _vm.icon
                      ? _c(
                          "v-icon",
                          { staticClass: "roal-rumble-card__icon" },
                          [_vm._v(_vm._s(_vm.icon))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }